import React, { useEffect, useState } from "react";
import qs from "qs";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getLandingPage, PageSettings, authorizeVipps } from "./landingpage.api";
import DefaultTemplate from "./templates/default";
import GodtLevertTemplate from "./templates/godtlevert";
import AdamsMatkasseTemplate from "./templates/adamsmatkasse";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
} from "@material-ui/core";

interface LandingProps {
  match?: any;
  location?: any;
}

export default function LandingPage(props: LandingProps) {
  const landingId = props.match.params.landingId;

  const [queryString, setQueryString] = useState<Object | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [landingSettings, setLandingSettings] = useState<PageSettings | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<Error | null>(null);
  const [enableErrorOk, setEnableErrorOk] = useState(false);
  const [authorizationUrl, setAuthorizationUrl] = useState<string | null>(null);
  const [showAuthorizationDialog, setShowAuthorizationDialog] = useState(false);

  useEffect(() => {
    setQueryString(
      qs.parse(props.location.search, { ignoreQueryPrefix: true })
    );

    setIsLoading(true);
    getLandingPage(landingId)
      .then((settings) => {
        setLandingSettings(settings);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.message === "State not found.") {
          setErrorMessage(
            new Error("Denne siden har utløpt og er ikke tilgjengelig.")
          );
          return;
        }
        setErrorMessage(error);
      });
  }, [landingId, props.location.search]);

  const onAuthorize = () => {
    authorizeVipps(landingId)
      .then((url: string) => {
        setAuthorizationUrl(url);
        setShowAuthorizationDialog(true);
      })
      .catch(setErrorMessage);
  };

  const onRedirectToVipps = () => {
    setShowAuthorizationDialog(false);
    if (authorizationUrl) {
      window.location.href = authorizationUrl as string;
    }

    if (authorizationUrl === null) {
      setErrorMessage(
        new Error(
          "En feil oppstod ved videresending til Vipps. Vennligst forsøk på nytt."
        )
      );
    }
  };

  const onReloadFromError = () => {
    setAuthorizationUrl(null);
    setErrorMessage(null);
  };

  let template = null;

  switch (landingSettings?.template) {
    case "godtlevert":
      template = () => (
        <GodtLevertTemplate
          onAuthorize={onAuthorize}
          settings={landingSettings}
        />
      );
      break;
    case "adamsmatkasse":
      template = () => (
        <AdamsMatkasseTemplate
          onAuthorize={onAuthorize}
          settings={landingSettings}
        />
      );
      break;
    default:
      template = () => <DefaultTemplate settings={landingSettings} />;
  }

  if (isLoading) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogContentText align="center">
            <CircularProgress />
            <br />
            Henter ordre
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div>
      {errorMessage ? (
        <Dialog open={true}>
          <DialogTitle>En feil oppstod</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorMessage.message}</DialogContentText>
          </DialogContent>
          {enableErrorOk ? (
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={onReloadFromError}
              >
                OK
              </Button>
            </DialogActions>
          ) : null}
        </Dialog>
      ) : (
        template()
      )}

      <Dialog open={showAuthorizationDialog}>
        <DialogTitle>Logg på med Vipps</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du vil nå bli videresendt til Vipps for pålogging.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowAuthorizationDialog(false)}
          >
            Avbryt
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onRedirectToVipps}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
