import React, { useEffect, useState } from "react";
import { PageSettings } from "../../landingpage.api";

interface DefaultTemplateProps {
  settings: PageSettings | null;
}

export default function DefaultTemplate(props: DefaultTemplateProps) {
  return <div>Note to dev: edit app/Http/Controllers/Oms/OrderController.php to add or set client ids.. then remove the test from order_landing_states and try again.</div>;
}
