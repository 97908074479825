import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LandingPage from "./landingpage.component";
import ConfirmationPage from "./confirmationpage.component";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/confirmation/" component={ConfirmationPage} />
          <Route path="/:landingId" component={LandingPage} />
          <Route
            path="/"
            component={() => {
              window.location.href = "https://www.fiko.no";
              return null;
            }}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
