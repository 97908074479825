import React, { useState } from "react";
import { PageSettings } from "../../landingpage.api";
import "./styles/main.scss";
import AdamsLogo from "./images/logo.svg";
import AdamsBanner from "./images/img.jpg";
import VippsLogo from "./images/vipps2.svg";

interface AdamsMatkasseTemplateProps {
  settings: PageSettings | null;
  onAuthorize: any;
}

export default function AdamsMatkasseTemplate(
  props: AdamsMatkasseTemplateProps
) {
  const [accept, setAccept] = useState(false);

  return (
    <div className="wrapper">
      <header className="header">
        <a href="#" className="logo">
          <img src={AdamsLogo} alt="Adams matkasse" />
        </a>
      </header>
      <main className="main">
        <div className="video-banner">
          <img src={AdamsBanner} alt="Adams matkasse" />
        </div>
        <form className="contact-form">
          <div className="form-row">
            <div className="form-group w-100">
              <label htmlFor="address">Gateadresse</label>
              <input
                type="text"
                readOnly
                value={props.settings?.street}
                className="input-control"
                id="address"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="zipcode">Postnummer</label>
              <input
                type="text"
                readOnly
                value={props.settings?.zip}
                className="input-control"
              />
            </div>
            <div className="form-group">
              <label>Sted</label>
              <input
                type="text"
                readOnly
                className="input-control w-70"
                value={props.settings?.city}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">E-post</label>
              <input
                type="email"
                readOnly
                value={props.settings?.email}
                className="input-control"
                id="email"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="delivery">Første levering</label>
              <input
                type="text"
                readOnly
                value={props.settings?.delivery_start}
                className="input-control"
                id="delivery"
              />
            </div>
            <div className="form-group">
              <label htmlFor="delivery2">Leveringsintervall</label>
              <input
                type="text"
                readOnly
                value={props.settings?.delivery_frequency}
                className="input-control"
                id="delivery2"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="input">Matkasse</label>
              <input
                type="text"
                readOnly
                value={props.settings?.product}
                className="input-control"
                id="input"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="column">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="price">Med rabatt</label>
                  <input
                    type="text"
                    readOnly
                    value={props.settings?.price}
                    className="input-control"
                    id="price"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="price2">Før rabatt</label>
                  <input
                    type="text"
                    readOnly
                    value={props.settings?.full_price}
                    className="input-control"
                    id="price2"
                  />
                </div>
              </div>
            </div>
            <div className="column flex-bottom">
              {accept ? <a href="#" className="button" onClick={props.onAuthorize}>
                <img src={VippsLogo} alt="vipps" />
              </a>
               : null}
            </div>
          </div>
          <div className="checkbox-row">
            <input
              type="checkbox"
              id="checkbox"
              checked={accept}
              onChange={(e) => setAccept(e.target.checked)}
            />
            <label htmlFor="checkbox">
              Jeg har lest SMS og godtar vilkårene ved å bekrefte denne ordren.
            </label>
          </div>
        </form>
      </main>
    </div>
  );
}
