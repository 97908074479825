import React, { useState } from "react";
import { PageSettings } from "../../landingpage.api";
import "./styles/main.scss";
import GodtLevertLogo from "./images/logo2.svg";
import GodtLevertBanner from "./images/img2.jpg";
import VippsLogo from "./images/vipps.svg";

interface GodtLevertTemplateProps {
  settings: PageSettings | null;
  onAuthorize: any;
}

export default function GodtLevertTemplate(props: GodtLevertTemplateProps) {
  const [accept, setAccept] = useState(false);
  return (
    <div className="container">
      <header className="header">
        <a href="#" className="logo">
          <img src={GodtLevertLogo} alt="GodtLevert" />
        </a>
      </header>
      <main className="main">
        <div className="video-banner">
          <img src={GodtLevertBanner} alt="image description" />
        </div>
        <form action="#" className="form-block">
          <div className="row-form">
            <div className="box w-100">
              <label htmlFor="address">Leveringsadresse</label>
              <input
                type="text"
                readOnly
                className="form-control"
                value={props.settings?.street}
              />
              <div className="row">
                <input
                  type="text"
                  readOnly
                  className="form-control w-30"
                  value={props.settings?.zip}
                />
                <input
                  type="text"
                  readOnly
                  className="form-control w-70"
                  value={props.settings?.city}
                />
              </div>
            </div>
          </div>
          <div className="row-form">
            <div className="box">
              <label htmlFor="email">E-post</label>
              <input
                type="text"
                readOnly
                className="form-control"
                value={props.settings?.email}
              />
            </div>
          </div>
          <div className="row-form">
            <div className="box">
              <label htmlFor="delivery">Første levering</label>
              <input
                type="text"
                readOnly
                className="form-control"
                value={props.settings?.delivery_start}
              />
            </div>
            <div className="box">
              <label htmlFor="delivery2">Leveringsfrekvens</label>
              <input
                type="text"
                readOnly
                className="form-control"
                value={props.settings?.delivery_frequency}
              />
            </div>
          </div>
          <div className="row-form">
            <div className="box">
              <label htmlFor="input">Matkasse</label>
              <input
                type="text"
                readOnly
                className="form-control"
                value={props.settings?.product}
              />
            </div>
          </div>
          <div className="row-form">
            <div className="column">
              <div className="row-form">
                <div className="box">
                  <label htmlFor="input3">Med rabatt</label>
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    value={props.settings?.price}
                  />
                </div>
                <div className="box">
                  <label htmlFor="input2">Før rabatt</label>
                  <input
                    type="text"
                    readOnly
                    className="form-control disable"
                    value={props.settings?.full_price}
                  />
                </div>
              </div>
            </div>
            <div className="column flex-bottom">
              {accept ? (
                <a href="#" className="button" onClick={props.onAuthorize}>
                  <img src={VippsLogo} alt="vipps" />
                </a>
              ) : null}
            </div>
          </div>
          <div className="checkbox-area">
            <input
              type="checkbox"
              id="checkbox"
              checked={accept}
              onChange={(e) => setAccept(e.target.checked)}
            />
            <label htmlFor="checkbox">
              Jeg har lest SMS og godtar vilkårene ved å bekrefte denne ordren.
            </label>
          </div>
        </form>
      </main>
    </div>
  );
}
