import React, { useEffect, useState } from "react";
import qs from "qs";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
} from "@material-ui/core";
import { confirmOrder } from "./landingpage.api";

interface ConfirmationProps {
  match?: any;
  location?: any;
}

interface ConfirmationQuery {
  code: string;
  state: string;
  scope: string;
}

export default function ConfirmationPage(props: ConfirmationProps) {
  const [queryString, setQueryString] = useState<ConfirmationQuery | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<Error | null>(null);
  const [orderConfirmed, setOrderConfirmed] = useState(false);

  useEffect(() => {
    const queryParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    setQueryString(queryParams);

    setIsLoading(true);
    confirmOrder(queryParams.state, queryParams.code, queryParams.scope, queryParams.state).then(() => {
      setIsLoading(false);
      setOrderConfirmed(true);
    }).catch(err => {
      setIsLoading(false);
      setErrorMessage(err);
    });
  }, [props.location.search]);

  const onReloadFromError = () => {
    setErrorMessage(null);
  };

  const onRestartConfirmation = () => {
    window.location.href = '/' + queryString!.state
  }

  if (isLoading) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogContentText align="center">
            <CircularProgress />
            <br />
            Bekrefter ordre
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div>
      {errorMessage ? (
        <Dialog open={true}>
          <DialogTitle>En feil oppstod</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorMessage.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onReloadFromError}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <>
        <Dialog open={orderConfirmed}>
          <DialogTitle>Ordre bekreftet</DialogTitle>
          <DialogContent>
            <DialogContentText>Du kan nå lukke denne siden.</DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog open={!orderConfirmed}>
          <DialogTitle>Ordre ikke bekreftet</DialogTitle>
          <DialogContent>
            <DialogContentText>En feil oppstod ved bekreftelse av ordren. Vennligst forsøk på nytt.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onRestartConfirmation}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        </>
      )}
    </div>
  );
}
